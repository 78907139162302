<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5193_57791)">
      <mask id="path-2-inside-1_5193_57791" fill="white">
        <path
          d="M23.9783 11.9958C23.9783 18.5905 18.6326 23.9361 12.038 23.9361C5.44357 23.9361 0.0976563 18.5905 0.0976562 11.9958C0.0976562 5.40133 5.44357 0.055542 12.038 0.055542C18.6326 0.055542 23.9783 5.40133 23.9783 11.9958Z"
        />
      </mask>
      <path
        d="M23.9783 11.9958C23.9783 18.5905 18.6326 23.9361 12.038 23.9361C5.44357 23.9361 0.0976563 18.5905 0.0976562 11.9958C0.0976562 5.40133 5.44357 0.055542 12.038 0.055542C18.6326 0.055542 23.9783 5.40133 23.9783 11.9958Z"
        fill="#1BA27A"
      />
      <path
        d="M0.0976562 11.9958H-0.902344H0.0976562ZM12.038 0.055542V-0.944458V0.055542ZM22.9783 11.9958C22.9783 18.0382 18.0803 22.9361 12.038 22.9361V24.9361C19.1849 24.9361 24.9783 19.1428 24.9783 11.9958H22.9783ZM12.038 22.9361C5.99584 22.9361 1.09766 18.0382 1.09766 11.9958H-0.902344C-0.902344 19.1428 4.89129 24.9361 12.038 24.9361V22.9361ZM1.09766 11.9958C1.09766 5.95362 5.99584 1.05554 12.038 1.05554V-0.944458C4.89129 -0.944458 -0.902344 4.84904 -0.902344 11.9958H1.09766ZM12.038 1.05554C18.0803 1.05554 22.9783 5.95361 22.9783 11.9958H24.9783C24.9783 4.84905 19.1849 -0.944458 12.038 -0.944458V1.05554Z"
        fill="black"
        fill-opacity="0.1"
        mask="url(#path-2-inside-1_5193_57791)"
      />
      <path
        d="M17.6422 6.07654H6.33594V8.80609H10.6243V12.818H13.3538V8.80609H17.6422V6.07654Z"
        fill="white"
      />
      <path
        d="M12.0154 13.2454C8.46793 13.2454 5.59187 12.684 5.59187 11.9913C5.59187 11.2988 8.46781 10.7372 12.0154 10.7372C15.5629 10.7372 18.4388 11.2988 18.4388 11.9913C18.4388 12.684 15.5629 13.2454 12.0154 13.2454ZM19.2279 12.2004C19.2279 11.3073 15.9988 10.5833 12.0154 10.5833C8.03211 10.5833 4.80273 11.3073 4.80273 12.2004C4.80273 12.9869 7.30673 13.6422 10.6248 13.7874V19.5467H13.3541V13.7896C16.6978 13.649 19.2279 12.991 19.2279 12.2004Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5193_57791">
        <rect width="24" height="24" rx="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
