<template>
    <svg class="icon_gold" width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M44.9952 23.9418C44.9984 23.7949 45 23.6476 45 23.5C45 12.1782 35.598 3 24 3C12.402 3 3 12.1782 3 23.5C3 23.6476 3.0016 23.7949 3.00478 23.9418L3 27.0244H3.00624C3.00223 27.1825 3 27.3409 3 27.5C3 38.2695 12.4019 47 24 47C35.5981 47 45 38.2695 45 27.5C45 27.3409 44.9978 27.1825 44.9938 27.0244H45L44.9952 23.9418Z"
            fill="#F8E464" />
        <path
            d="M24 47C35.5981 47 45 38.2695 45 27.5C45 27.3409 44.9978 27.1825 44.9938 27.0244H45L44.9938 23L44.2354 22.2683C41.7731 14.0394 33.6463 8 24 8C14.3537 8 6.22686 14.0394 3.76458 22.2683L3.00624 23L3 27.0244H3.00624C3.00223 27.1825 3 27.3409 3 27.5C3 38.2695 12.4019 47 24 47Z"
            fill="#C37622" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M35 10.8861C31.801 9.05584 28.0333 8 24 8C19.9667 8 16.199 9.05584 13 10.8861V44.114C16.1991 45.9442 19.9667 47 24 47C28.0333 47 31.8009 45.9442 35 44.114V10.8861Z"
            fill="#B2601D" />
        <ellipse cx="24" cy="23.5" rx="21" ry="20.5" fill="#F8E464" />
        <path
            d="M38.8492 9.00431C36.8992 7.10071 34.5842 5.59069 32.0363 4.56047C29.4885 3.53025 26.7578 3 24 3C21.2422 3 18.5115 3.53025 15.9636 4.56047C13.4158 5.59069 11.1008 7.10071 9.15075 9.00432L24 23.5L38.8492 9.00431Z"
            fill="#FCF3B6" />
        <path d="M29.4352 3.69852C25.8745 2.76716 22.1255 2.76716 18.5648 3.69852L24 23.5L29.4352 3.69852Z" fill="white" />
        <path
            d="M5.81347 33.75C7.6566 36.8664 10.3076 39.4543 13.5 41.2535C16.6924 43.0528 20.3137 44 24 44C27.6863 44 31.3076 43.0528 34.5 41.2535C37.6924 39.4543 40.3434 36.8664 42.1865 33.75L24 23.5L5.81347 33.75Z"
            fill="#EDB93D" />
        <path
            d="M13.5 41.2535C16.6924 43.0528 20.3137 44 24 44C27.6863 44 31.3076 43.0528 34.5 41.2535L24 23.5L13.5 41.2535Z"
            fill="#DE9327" />
        <ellipse cx="24" cy="23" rx="17" ry="16" fill="url(#paint0_linear_2007_129486)"
            stroke="url(#paint1_linear_2007_129486)" />
        <ellipse cx="24" cy="23.5" rx="15" ry="14.5" fill="#DA942D" />
        <path d="M14 31V16L19 20L24 15L29 20L34 16V31H14Z" fill="#F9E465" />
        <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M24 31H34V16L29 20L24 15V31Z" fill="#EDB93D" />
        <rect x="14" y="29" width="20" height="2" fill="#ECB63B" />
        <path d="M24 29V15L19 20V29H24Z" fill="#D9D9D9" />
        <path d="M24 29V15L19 20V29H24Z" fill="#FCF3B6" />
        <path d="M24 29V15L29 20V29H24Z" fill="#D9D9D9" />
        <path d="M24 29V15L29 20V29H24Z" fill="#F8E464" />
        <defs>
            <linearGradient id="paint0_linear_2007_129486" x1="24" y1="7" x2="24" y2="39" gradientUnits="userSpaceOnUse">
                <stop stop-color="#B86314" />
                <stop offset="1" stop-color="#D6881B" />
            </linearGradient>
            <linearGradient id="paint1_linear_2007_129486" x1="24" y1="7" x2="24" y2="39" gradientUnits="userSpaceOnUse">
                <stop stop-color="#DE9327" />
                <stop offset="1" stop-color="#FCF3B6" />
            </linearGradient>
        </defs>
    </svg>
</template>