<template>
  <div class="ui_input_2">
    <input :style="{ width: width, height: height, backgroundColor: backgroundcolor, color: color }" :type="dataType"
      :data-type="type" :value="modelValue" :placeholder="placeholder" @input="updateInputVal"
      :class="{ err: err && !focusTag }" @keyup="parseToInt" @afterpaste="parseToInt" @blur="Blur" :data-blur="'blur'" />
    <div class="ico" v-if="type == 'password'" @click="swithIco">
      <span class="hide" v-show="dataType != 'password'"><img src="https://cdn.blockwar.io/web_assets/img/ico_hide.png"
          alt="" /></span>
      <span class="show" v-show="dataType == 'password'"><img src="https://cdn.blockwar.io/web_assets/img/ico_show.png"
          alt="" /></span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.ui_input {
  position: relative;
  width: 100%;

  i {
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    top: 0.14rem;
    right: 0.2rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

input {
  display: block;
  outline: none;
  width: 5.64rem;
  border: 1px solid #34395b;
  background-color: #34395b;
  border-radius: 0.1rem;
  padding: 0.13rem 0.2rem;
  font-size: 0.16rem;
  font-weight: bold;
  line-height: 0.22rem;
  color: #fff;
  box-sizing: border-box;

  &:hover {
    border-color: #828282;
  }

  &:focus {
    background: #060a25;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
}

input[data-type="password"] {
  padding-right: 0.64rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<script>
import { defineComponent, ref } from "vue";
import common from "../../utils/common";
// import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "ui_input",
  props: {
    width: {
      default: "100%",
    },
    height: {
      default: '0.48rem'
    },
    backgroundcolor: {
      default: '#1B203F'
    },
    color: {
      default: '#fff'
    },
    type: {
      default: "text",
    },
    placeholder: {
      default: "",
    },
    modelValue: {
      required: true,
    },
    err: {
      default: false,
    },
    errtxt: {
      default: "",
    },
  },
  emits: ["update:modelValue", "blur"],
  components: {},
  setup(props, ctx) {
    // let router = useRouter();
    //   let store = useStore();
    let dataType = ref("password");
    if (props.type === "number1") {
      dataType.value = "number";
    } else {
      dataType.value = props.type;
    }

    let focusTag = ref(false);
    const updateInputVal = ($event) => {
      focusTag.value = false;
      ctx.emit("update:modelValue", common.stringTrim($event.target.value));
    };
    const Blur = function () {
      console.log(111111)
      ctx.emit("blur", common.stringTrim($event.target.value));
    }
    const inputAndFoucs = () => {
      focusTag.value = true;
      // ctx.emit("update:val", "");
    };
    const swithIco = () => {
      if (dataType.value == "password") {
        dataType.value = "text";
      } else {
        dataType.value = "password";
      }
    };

    // 只能正整数
    const parseToInt = () => {
      if (dataType.value == 'number' && props.type != "number1") {
        let t = props.modelValue + '';
        if (t.length == 1) {
          t = t.replace(/[^1-9]/g, '');
        }
        else {
          t = t.replace(/\D/g, '');
        }
        ctx.emit("update:modelValue", common.stringTrim(t));
      }
    }
    return { parseToInt, updateInputVal, inputAndFoucs, focusTag, dataType, swithIco };
  },
});
</script>
