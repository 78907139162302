<template>
  <div class="template ybf u_exchange">
    <div class="layout_left_right">
      <div class="layout">
        <div class="layout_right">
          <div id="exchangebox">
            <div class="caption">
              <div class="block_layout">
                <span class="back" @click="toMywallet(null)">
                  <icon_back />
                  <span class="onlypc">{{ lang == "en" ? "Go Back" : "返回" }}</span>
                  <span class="onlymobile t">易幣付</span>
                </span>
                <div class="detail_title onlypc">易幣付</div>
              </div>
            </div>
            <div class="block_layout">
              <div class="finance block">
                <h2>{{ lang == "en" ? "Gold Amount" : "金幣數量" }}</h2>
                <div class="flex">
                  <div class="ico">
                    <icon_gold />
                  </div>
                  <div class="data">
                    <div>{{ userGold }}</div>
                  </div>
                </div>
              </div>
              <div class="block">
                <div class="content main ybf" v-show="!queryString.deposit_order_id">
                  <div id="tips" v-if="showTipsTag">
                    <div class="box">
                      <div class="box_left">
                        <svg
                          width="44"
                          height="50"
                          viewBox="0 0 44 50"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="22" cy="24.6693" r="12" fill="#0080FE" />
                          <path
                            d="M20.6662 21.5582H23.3329V29.5582H25.1107V31.3359H19.7773V29.5582H21.5551V23.3359H20.6662V21.5582Z"
                            fill="white"
                          />
                          <ellipse
                            cx="22.0003"
                            cy="19.3359"
                            rx="1.33333"
                            ry="1.33333"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div class="box_right">
                        {{
                          lang == "en"
                            ? "Reminder: 1 USDT = 1000 Gold"
                            : "提示：1USDT = 1000金幣"
                        }}
                        <div>
                          {{
                            lang == "en"
                              ? "Download 易幣付 App and Pay with CNY"
                              : "下載易幣付APP後支持CNY支付"
                          }}
                          <a href="https://www.yibifu.net/" target="_blank">{{
                            lang == "en" ? "DOWNLOAD" : "官網下載"
                          }}</a>
                        </div>
                      </div>
                    </div>
                    <svg
                      class="close"
                      @click="showTipsTag = false"
                      width="27"
                      height="28"
                      viewBox="0 0 27 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8 10.2368L9.73684 8.5L13.5 12.2632L17.2632 8.5L19 10.2368L15.2368 14L19 17.7632L17.2632 19.5L13.5 15.7368L9.73684 19.5L8 17.7632L11.7632 14L8 10.2368Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <dl>
                    <dt>{{ lang == "en" ? "Coin Type" : "虛擬幣種類" }}</dt>
                    <dd class="on">
                      <span><icon_usdt />USDT</span>
                    </dd>
                  </dl>
                  <dl>
                    <dt>{{ lang == "en" ? "Channel" : "渠道" }}</dt>
                    <dd
                      :class="{ on: sendData.coin_type == item.val }"
                      v-for="(item, inx) in coinType"
                      :key="inx"
                      @click="sendData.coin_type = item.val"
                    >
                      <span>{{ item.name }}</span>
                    </dd>
                  </dl>
                  <div class="dl">
                    <span class="label">{{
                      lang == "en" ? "Buy Amount" : "購買數量"
                    }}</span>
                    <div class="input">
                      <Iput2
                        :placeholder="
                          lang == 'en' ? 'Minimum Amount: 10000' : '最低數額: 10000'
                        "
                        v-model.number.trim="sendData.amount"
                        :width="'100%'"
                        :backgroundcolor="'#1B203F'"
                        :heigth="'.48rem'"
                        :type="'number'"
                      />
                      <icon_gold class="icon" />
                    </div>
                  </div>
                  <ui_btn_1
                    v-if="sendData.amount >= 10000 && userInfo"
                    class="btn"
                    @click="send"
                    :width="'100%'"
                    :height="'0.48rem'"
                    >{{ lang == "en" ? "Confirm Buy" : "確認購買" }}</ui_btn_1
                  >
                  <ui_btn_1
                    v-else
                    :disclick="true"
                    class="btn"
                    :width="'100%'"
                    :height="'0.48rem'"
                    >{{ lang == "en" ? "Confirm Buy" : "確認購買" }}</ui_btn_1
                  >
                </div>
                <div class="content main ybf" v-show="queryString.deposit_order_id">
                  <ul class="form">
                    <li>
                      <span class="label"
                        >{{ lang == "en" ? "Order ID" : "訂單號" }}:
                      </span>
                      <span class="val">{{ queryString.deposit_order_id }}</span>
                    </li>
                    <li>
                      <span class="label">{{
                        lang == "en" ? "Buy Amount" : "購買數量"
                      }}</span>
                      <span class="val"></span>&ensp;
                      <icon_gold class="icon" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="../../components/u/exchange_v2.scss"></style>
<style lang="scss" scoped src="../u.scss"></style>
<style lang="scss" scoped>
a {
  color: #fff;
  margin-left: 0.2rem;
  font-size: 16px;
}
.input {
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.title {
  height: 0.6rem;
  background: rgba(38, 37, 76, 0.5);
  padding-left: 0.6rem;
  display: flex;
  align-items: center;
  font-size: 0.18rem;
}
#tips {
  margin-left: 1.2rem;
  width: 6rem;
  box-sizing: border-box;
  background: rgba(78, 127, 255, 0.3);
  border-radius: 0.05rem;
  color: rgba(255, 255, 255, 0.8);
  position: relative;
  top: -0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.1rem;
  font-size: 0.14rem;
  .box {
    display: flex;
    align-items: center;
    .box_left {
      margin-right: 0.1rem;
    }
    .box_right {
      div {
        margin-top: 0.1rem;
        color: #1480ff;
        a {
          padding: 0.05rem 0.1rem;
          background: #0080fe;
          border-radius: 0.04rem;
          color: #fff;
          cursor: pointer;
          text-decoration: none;
          font-size: 0.14rem;
        }
      }
    }
  }
  .close {
    cursor: pointer;
  }
}
</style>

<script setup>
import axios from "axios";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import common from "../../utils/common";
import icon_back from "@/components/ui/icon/icon_back.vue";
import { useStore } from "vuex";
import icon_gold from "@/components/ui/icon/icon_gold";
import ui_btn_1 from "@/components/ui/ui_btn_1.vue";
import Iput2 from "@/components/ui/ui_input_2.vue";
import Select from "@/components/ui/ui_select.vue";
import { useRouter } from "vue-router";
import icon_usdt from "@/components/ui/icon/icon_usdt";
let store = useStore();
let router = useRouter();

let queryString = computed(() => {
  return router.currentRoute.value.query;
});

let lang = computed(() => {
  return store.state.lang;
});
let props = defineProps(["modelValue", "gold", "u"]);
let emites = defineEmits(["update:modelValue"]);

let userInfo = computed(() => {
  return store.state.userInfo;
});
watch(userInfo, (newVal, oldVal) => {
  console.log("userInfo newVal", newVal.uid);
  if (newVal && newVal.uid) {
    queryFinance();
    // 查詢訂單信息
    if (queryString.value.deposit_order_id) {
      queryOrder();
    }
  }
});
const toMywallet = (orderId) => {
  emites("update:modelValue", null);
  localStorage.removeItem("uDetailItem");
  if (orderId) router.push("/u/detail?first=true&type=ybf&order_id=" + orderId);
};

// 用戶的金幣和U幣
let userGold = ref();
let userU = ref();
var queryFinance = () => {
  common.queryFinance().then((r) => {
    if (r.data.code == 0 && r.data.infos.length >= 0) {
      const userFinanceInfo = r.data.infos[0][userInfo.value.uid];
      console.log("--lb", userFinanceInfo);
      if (userFinanceInfo) {
        userGold.value = common.getValueByFinanceType(
          common.financeType.gold,
          userFinanceInfo
        );
        userU.value =
          common.getValueByFinanceType(common.financeType.diamond, userFinanceInfo) /
          1000;
      }
    }
  });
};

let sendUrl = ref(common.userApi.requestUrl + "/finance_api/ebpay/deposit");
let sendData = ref({
  amount: "",
  card_holder_name: userInfo.value ? userInfo.value.nick : "",
  coin_type: "4",
  currency: "1", // 1=usdt; -1=CNY
  pay_type: "2",
  payment_type: "1",
  remark: "",
  return_url: location.href,
  telephone: "",
});

let coinType = ref([
  {
    name: "TRC20",
    val: "4",
    default: true,
    remark:
      "CoinTypeWithChain.USDT_TRC20(type=4, symbol=usdt-trc20, precision=6, byteLength=0)",
  },
  {
    name: "ERC20",
    val: "2",
    default: false,
    remark:
      "CoinTypeWithChain.USDT_ERC20(type=2, symbol=usdt-erc20, precision=6, byteLength=0)",
  },
]);

const set_coin_type = (v) => {
  sendData.value.coin_type = v;
};

let pay_url = ref();
const send = () => {
  try {
    let postData = JSON.parse(JSON.stringify(sendData.value));
    postData.amount = (sendData.value.amount / 1000).toFixed(3);
    postData.telephone = "138" + userInfo.value.uid;
    common.showLoading("yibifu send");
    axios
      .post(sendUrl.value, common.buildSendData(postData))
      .then((r) => {
        queryFinance();
        if (r.data.code != 0) {
          toastr["error"](r.data.msg + ": " + r.data.ebpay_msg);
        }
        if (r.data.pay_url) {
          pay_url.value = r.data.pay_url;
          window.open(r.data.pay_url);
          //   router.push({
          //     path: "/ybf",
          //     query: { deposit_order_id: r.data.deposit_order_id },
          //   });
          toMywallet(r.data.deposit_order_id);
        }
      })
      .catch((e) => {})
      .finally(() => {
        common.hideLoading("yibifu send");
      });
  } catch (e) {}
};

var queryOrder = () => {
  const sendUrl =
    common.userApi.requestUrl +
    "/finance_api/ebpay/deposit_orders?deposit_order_id=" +
    queryString.value.deposit_order_id;
  axios.get(sendUrl).then(() => {});
};

let showTipsTag = ref(true);

onMounted(() => {
  if (userInfo.value) {
    queryFinance();
    // 查詢訂單信息
    if (queryString.value.deposit_order_id) {
      queryOrder();
    }
  }
});
</script>
